"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.i18n = void 0;
exports.loadLanguageAsync = loadLanguageAsync;
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.push.js");
var _vue = _interopRequireDefault(require("vue"));
var _vueI18n = _interopRequireDefault(require("vue-i18n"));
// import axios from 'axios'
_vue.default.use(_vueI18n.default);
var i18n = exports.i18n = new _vueI18n.default({
  // locale: 'zh', // 设置语言环境
  messages: {
    // 'zh':messages
  } // 设置语言环境信息
});
var loadedLanguages = []; // 默认语言
function setI18nLanguage(lang) {
  i18n.locale = lang;
  // axios.defaults.headers.common['Accept-Language'] = lang
  // document.querySelector('html').setAttribute('lang', lang)
  return lang;
}
function loadLanguageAsync(lang) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return import( /* webpackChunkName: "lang-[request]" */"@/lang/".concat(lang, ".js")).then(function (msgs) {
        i18n.setLocaleMessage(lang, msgs.default);
        loadedLanguages.push(lang);
        return setI18nLanguage(lang);
      });
    }
    return Promise.resolve(setI18nLanguage(lang));
  }
  return Promise.resolve(lang);
}