"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.filterAsyncRoutes = filterAsyncRoutes;
var _objectSpread2 = _interopRequireDefault(require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/esm/objectSpread2.js"));
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/es.json.stringify.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.concat.js");
var _router = require("@/router");
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return store.getters.scope === 2 && (route.path === '/clue' || route.path === '/chat') ? false : store.getters.scope === 2 && route.meta.roles.includes(222) || store.getters.scope === 1 && route.meta.roles.includes(111) && (JSON.stringify(route.meta.roles) === '[111]' || roles.some(function (role) {
      return route.meta.roles.includes(role);
    }));
  }
  {
    return true;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = (0, _objectSpread2.default)({}, route);
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      //非定額套餐的公司展示"取得可能リスト" type: 1定額 2非定額
      if (!(tmp.name === "Clue" && store.state.user.type === 1)) {
        res.push(tmp);
      }
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = _router.constantRoutes.concat(routes);
  },
  SET_NO_ROUTES: function SET_NO_ROUTES(state, routes) {
    state.addRoutes = [];
    state.routes = [];
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, roles) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var accessedRoutes = filterAsyncRoutes(_router.asyncRoutes, roles);
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};