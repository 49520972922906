"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addClass = addClass;
exports.byteLength = byteLength;
exports.cleanArray = cleanArray;
exports.createUniqueString = createUniqueString;
exports.debounce = debounce;
exports.deepClone = deepClone;
exports.formatDate = formatDate;
exports.formatTime = formatTime;
exports.getCanvasBase64 = getCanvasBase64;
exports.getImage = getImage;
exports.getLastDays = getLastDays;
exports.getQueryObject = getQueryObject;
exports.getTime = getTime;
exports.hasClass = hasClass;
exports.html2Text = html2Text;
exports.isFromWeiXin = isFromWeiXin;
exports.objectMerge = objectMerge;
exports.param = param;
exports.param2Obj = param2Obj;
exports.parseTime = parseTime;
exports.randomString = randomString;
exports.removeClass = removeClass;
exports.showTimePipe = showTimePipe;
exports.toggleClass = toggleClass;
exports.uniqueArr = uniqueArr;
exports.uploadValid = uploadValid;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.test.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.string.pad-start.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/web.url.js");
require("core-js/modules/web.url-search-params.js");
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.set.js");
require("core-js/modules/es.string.match.js");
require("core-js/modules/es.regexp.constructor.js");
require("core-js/modules/es.regexp.dot-all.js");
require("core-js/modules/es.regexp.sticky.js");
require("core-js/modules/es.array.concat.js");
var _typeof2 = _interopRequireDefault(require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/esm/typeof.js"));
var _elementUi = require("element-ui");
var $ = require('../../public/static/jquery.min');
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if ((0, _typeof2.default)(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{([ymdhisa])+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
  return time_str;
}
function formatDate(date) {
  var formatNumber = function formatNumber(n) {
    n = n.toString();
    return n[1] ? n : '0' + n;
  };
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  return [year, month, day].map(formatNumber).join('');
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf('?') + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xDC00 && code <= 0xDFFF) i--;
  }
  return s;
}
function getBase64Image(img, width, height) {
  var canvas = document.createElement("canvas");
  canvas.width = width ? width : img.width;
  canvas.height = height ? height : img.height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  var dataURL = canvas.toDataURL();
  return dataURL;
}
function getCanvasBase64(img) {
  var image = new Image();
  //至关重要
  image.setAttribute('crossOrigin', 'anonymous');
  image.src = img + '?time=' + new Date().valueOf();
  ;
  //至关重要
  var deferred = $.Deferred();
  if (img) {
    image.onload = function () {
      deferred.resolve(getBase64Image(image)); //将base64传给done上传处理
      //document.getElementById("container2").appendChild(image);
    };
    return deferred.promise(); //问题要让onload完成后再return sessionStorage['imgTest']
  }
}
/**
 * @param {Array} actual
 * @returns {Array}
 */
function cleanArray(actual) {
  var newArray = [];
  for (var _i = 0; _i < actual.length; _i++) {
    if (actual[_i]) {
      newArray.push(actual[_i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
function param(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return '';
    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
  })).join('&');
}
function getImage(url, imgId) {
  var xhr = new XMLHttpRequest();
  xhr.open('get', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (this.status == 200) {
      document.getElementById(imgId).src = URL.createObjectURL(this.response);
    }
  };
  xhr.send(null);
}
/**
 * @param {string} url
 * @returns {Object}
 */
function param2Obj(url) {
  var search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

/**
 * @param {string} val
 * @returns {string}
 */
function html2Text(val) {
  var div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
function objectMerge(target, source) {
  if ((0, _typeof2.default)(target) !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];
    if ((0, _typeof2.default)(sourceProperty) === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  var classString = element.className;
  var nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

/**
 * @param {string} type
 * @returns {Date}
 */
function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;
  var later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
function deepClone(source) {
  if (!source && (0, _typeof2.default)(source) !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && (0, _typeof2.default)(source[keys]) === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
function createUniqueString() {
  var timestamp = +new Date() + '';
  var randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}
/**
 * 获取当前日期的前N天的日期数组
 * @param {number} days天数
 * @param {Array} 日期数组
 */
function getLastDays(days) {
  var lastMonth = [];
  for (var i = 0; i < days; i++) {
    var day = new Date(new Date().setDate(new Date().getDate() - i)).toLocaleString('zh', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }).substring(5, 10);
    lastMonth.unshift(day);
  }
  return lastMonth;
}
/**
 * 上传图片尺寸大小校验
 * @param {file} 文件
 */
function uploadValid(file, w, h) {
  var width = w || 8064;
  var height = h || 6048;
  var isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    _elementUi.Message.error("\u30B5\u30A4\u30BA\u304C10MB\u4EE5\u4E0B\u3001\u304B\u3064\u89E3\u50CF\u5EA6\u304C".concat(width, "\xD7").concat(height, "\u672A\u6E80\u306E\u753B\u50CF\u3092\u4F7F\u7528\u3057\u3066\u304F\u3060\u3055\u3044"));
    return false;
  }
  var isSize = new Promise(function (resolve, reject) {
    var _URL = window.URL || window.webkitURL;
    var image = new Image();
    image.onload = function () {
      var valid = image.width <= width && image.height <= height;
      valid ? resolve() : reject();
    };
    image.src = _URL.createObjectURL(file);
  }).then(function () {
    return file;
  }, function () {
    _elementUi.Message.error("\u30B5\u30A4\u30BA\u304C10MB\u4EE5\u4E0B\u3001\u304B\u3064\u89E3\u50CF\u5EA6\u304C".concat(width, "\xD7").concat(height, "\u672A\u6E80\u306E\u753B\u50CF\u3092\u4F7F\u7528\u3057\u3066\u304F\u3060\u3055\u3044"));
    return Promise.reject();
  });
  return isSize;
}
/**
 * 随机数
 * @param {number}
 */
function randomString(e) {
  e = e || 32;
  var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
    a = t.length,
    n = "";
  for (i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n;
}
/**
 * 判断是否是微信浏览器打开
 * @param {boolean}
 */
function isFromWeiXin() {
  var ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) === 'micromessenger') {
    return true;
  } else {
    return false;
  }
}
/**
 * 聊天时间展示
 * @param {unix_stamp} 时间戳
 */
function showTimePipe(unix_stamp) {
  // unix_stamp 精确到微秒
  var _today_obj = new Date();
  var _today_date = {
    y: _today_obj.getFullYear(),
    m: _today_obj.getMonth() + 1 < 10 ? '0' + (_today_obj.getMonth() - -1) : _today_obj.getMonth() - -1,
    d: _today_obj.getDate() < 10 ? '0' + _today_obj.getDate() : _today_obj.getDate()
  };
  var _today_stamp = Date.parse(_today_date.y + '/' + _today_date.m + '/' + _today_date.d + ' 00:00:00');
  var stamp = [];
  stamp[0] = _today_stamp + 86400000;
  stamp[1] = _today_stamp;
  stamp[2] = _today_stamp - 86400000;
  stamp[3] = _today_stamp - 172800000;
  stamp[4] = _today_stamp - 518400000; // 7天

  stamp[5] = _today_stamp - 31536000000; // 365天

  var _compare_obj = new Date();
  _compare_obj.setTime(unix_stamp);
  var return_str;
  if (unix_stamp >= stamp[1] && unix_stamp < stamp[0]) {
    return_str = _compare_obj.getHours() + ':' + (_compare_obj.getMinutes() < 10 ? '0' + _compare_obj.getMinutes() : _compare_obj.getMinutes());
  } else if (unix_stamp >= stamp[2] && unix_stamp < stamp[1]) {
    var yesterdayText = '昨天';
    return_str = yesterdayText + ' ' + _compare_obj.getHours() + ':' + (_compare_obj.getMinutes() < 10 ? '0' + _compare_obj.getMinutes() : _compare_obj.getMinutes());
  } else if (unix_stamp >= stamp[3] && unix_stamp < stamp[2]) {
    var theDayBeforeYesterdayText = '前天';
    return_str = theDayBeforeYesterdayText + ' ' + _compare_obj.getHours() + ':' + (_compare_obj.getMinutes() < 10 ? '0' + _compare_obj.getMinutes() : _compare_obj.getMinutes());
  } else if (unix_stamp >= stamp[4] && unix_stamp < stamp[3]) {
    // 7天内
    var daynames = ['天', '一', '二', '三', '四', '五', '六'];
    var dathStr = '星期' + daynames[_compare_obj.getDay()];
    var SundayText = '星期天';
    var MondayText = '星期一';
    var TuesdayText = '星期二';
    var WednesdayText = '星期三';
    var ThursdayText = '星期四';
    var FridayText = '星期五';
    var SaturdayText = '星期六';
    var dathStr2;
    switch (dathStr) {
      case '星期天':
        dathStr2 = SundayText;
        break;
      case '星期一':
        dathStr2 = MondayText;
        break;
      case '星期二':
        dathStr2 = TuesdayText;
        break;
      case '星期三':
        dathStr2 = WednesdayText;
        break;
      case '星期四':
        dathStr2 = ThursdayText;
        break;
      case '星期五':
        dathStr2 = FridayText;
        break;
      case '星期六':
        dathStr2 = SaturdayText;
        break;
      default:
        dathStr2 = dathStr;
        break;
    }
    return_str = dathStr2 + ' ' + _compare_obj.getHours() + ':' + (_compare_obj.getMinutes() < 10 ? '0' + _compare_obj.getMinutes() : _compare_obj.getMinutes());
  } else if (unix_stamp >= stamp[5] && unix_stamp < stamp[4]) {
    // 365天内
    var monthText = '月';
    var dayText = '日';
    return_str = _compare_obj.getMonth() - -1 + monthText + _compare_obj.getDate() + dayText + ' ' + _compare_obj.getHours() + ':' + (_compare_obj.getMinutes() < 10 ? '0' + _compare_obj.getMinutes() : _compare_obj.getMinutes());
  } else {
    var year = '年';
    var month = '月';
    var day = '日';
    return_str = _compare_obj.getFullYear() + year + (_compare_obj.getMonth() - -1) + month + _compare_obj.getDate() + day + ' ' + _compare_obj.getHours() + ':' + (_compare_obj.getMinutes() < 10 ? '0' + _compare_obj.getMinutes() : _compare_obj.getMinutes());
  }
  return return_str;
}