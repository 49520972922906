"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.function.name.js");
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  email: function email(state) {
    return state.user.email;
  },
  name: function name(state) {
    return state.user.name;
  },
  agentId: function agentId(state) {
    return state.user.agent_id;
  },
  useTranslate: function useTranslate(state) {
    return state.user.is_use_translate;
  },
  is_perfect: function is_perfect(state) {
    return state.user.is_perfect;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  domains: function domains(state) {
    return state.user.domains;
  },
  company: function company(state) {
    return state.user.company;
  },
  showAdv: function showAdv(state) {
    return state.user.showAdv;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  language: function language(state) {
    return state.app.language;
  },
  quota: function quota(state) {
    return state.user.quota;
  },
  showClue: function showClue(state) {
    return state.user.showClue;
  },
  type: function type(state) {
    return state.user.type;
  },
  isCustimerClue: function isCustimerClue(state) {
    return state.user.isCustimerClue;
  },
  scope: function scope(state) {
    return state.user.scope;
  },
  estate_form: function estate_form(state) {
    return state.estate.estate_form;
  }
};
var _default = exports.default = getters;