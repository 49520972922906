"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/esm/objectSpread2.js"));
var _index = _interopRequireDefault(require("../components/Sidebar/index"));
var _Navbar = _interopRequireDefault(require("../components/Navbar"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'PzHeader',
  components: {
    Logo: _index.default,
    Navbar: _Navbar.default
  },
  data: function data() {
    return {
      title: '株式会社神居秒算'
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    }
  }))
};