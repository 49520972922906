"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.array.push.js");
var _vue = _interopRequireDefault(require("vue"));
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _router = _interopRequireDefault(require("@/router"));
// import { isFromWeiXin } from '@/utils/index'

var arr = ['/v1/estate/sales', '/v1/applet/mansion/store', '/v1/applet/estate/sales/save'];
// create an axios instance
var service = _axios.default.create({
  withCredentials: true,
  // send cookies when cross-domain requests
  timeout: 40000 // request timeout
});
// request interceptor
service.interceptors.request.use(function (config) {
  config.baseURL = config.baseUrl || process.env.VUE_APP_BASE_API;
  config.headers = {
    'Accept': 'application/json',
    'Content-Type': config.content_type || 'application/json',
    'Accept-Language': _store.default.getters.language || (window.navigator.language.indexOf('ja') != -1 ? 'ja' : 'en') || 'ja',
    'Authorization': _store.default.getters.token ? 'Bearer ' + _store.default.getters.token : ''
  };
  return config;
}, function (error) {
  // do something with request error
  return Promise.reject(error);
});
// response interceptor
service.interceptors.response.use(
/**
 * Ifa you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  if (res.code === 1000200 || res.code === 200 || response.request.responseType === 'blob') {
    return res;
  }
  if (res && res.code === 1000401) {
    // if (isFromWeiXin()) {
    //   // window.location.href = '/bindWX' // 如果是微信打开需要判断是否绑定微信
    //   let redirect = window.location.pathname // 校验完成后的跳转地址
    //   router.push(`/bindWX?redirect=${redirect}`)
    // } else {
    _store.default.dispatch('user/resetInfo').then(function () {
      _router.default.push({
        path: '/login'
      });
    });
    // }
  } else if (res && res.code - 0 === 1301003) {
    _store.default.dispatch('user/resetInfo').then(function () {
      _router.default.push({
        path: '/login'
      });
    });
  } else {
    (0, _elementUi.Message)({
      message: res.msg,
      type: 'error',
      duration: 5 * 1000
    });
  }
  return Promise.reject(res);
}, function (error) {
  if (error.response && error.response.status && error.response.status - 0 === 1301003) {
    _store.default.dispatch('user/resetInfo').then(function () {
      if (_router.default.history.current.path.indexOf('/login') === -1) _router.default.push({
        path: '/login'
      });
    });
  }
  var originalRequest = error.config;
  originalRequest.baseURL = '';
  if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1 && !originalRequest._retry) {
    originalRequest._retry = true;
    return _axios.default.request(originalRequest);
  }
  if (error.response && error.response.data && error.response.data.code && (error.response.data.code - 0 !== 1301003 || error.response.data.code - 0 === 1301003 && error.response.config.url.indexOf('/v1/auth/signin') !== -1)) {
    (0, _elementUi.Message)({
      message: error.response && error.response.data && error.response.data.msg,
      type: 'error',
      duration: 3 * 1000
    });
    return;
  }
  if ((error.response && error.response.status && error.response.status - 0) !== 1301003) {
    (0, _elementUi.Message)({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    });
  }
  return Promise.reject(error);
});
var _default = exports.default = service;