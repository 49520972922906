"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PzHeader = _interopRequireDefault(require("@/layout/components/PzHeader.vue"));
//
//
//
//
//
//
//
// import { loadLanguageAsync} from '@/lang/index'
var _default = exports.default = {
  name: 'App',
  components: {
    pzHeader: _PzHeader.default
  },
  data: function data() {
    return {
      isRouterActive: true,
      isPanzhi: false
    };
  },
  watch: {
    $route: function $route(route) {
      var globalPath = ['/overseasList', '/overseasEdit'];
      this.isPanzhi = route.path === '/panzhi';
      // let lang = globalPath.includes(route.path) ? 'en' : 'ja';
      // lang = route.path === '/login' ? window.navigator.language : lang;
      // this.$store.dispatch('app/setLanguage', lang);
      // loadLanguageAsync(lang);
    }
  }
};