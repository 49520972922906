"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _interopRequireWildcard = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.array.filter.js");
require("/builds/frontend/web/agent/node_modules/core-js/modules/es.array.iterator.js");
require("/builds/frontend/web/agent/node_modules/core-js/modules/es.promise.js");
require("/builds/frontend/web/agent/node_modules/core-js/modules/es.object.assign.js");
require("/builds/frontend/web/agent/node_modules/core-js/modules/es.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("./icons");
require("./permission");
require("./utils/error-log");
var filters = _interopRequireWildcard(require("./filters"));
var _index2 = require("@/lang/index");
var _locale = _interopRequireDefault(require("element-ui/lib/locale"));
require("./assets/iconfont/iconfont.css");
var _htmlToPdf = _interopRequireDefault(require("@/utils/htmlToPdf"));
var Sentry = _interopRequireWildcard(require("@sentry/vue"));
var _tracing = require("@sentry/tracing");
var _this = this; // a modern alternative to CSS resets
// global css
// icon
// permission control
// error log
// global filters
// import locale from 'element-ui/lib/locale/lang/ja'
// import zh_CN from '@/lang/zh_CN'
// import ja from '@/lang/ja'
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue: _vue.default,
    dsn: "https://4951154f7cfb45889ce8c700e8dd5e4b@o70409.ingest.sentry.io/5867876",
    integrations: [new _tracing.Integrations.BrowserTracing()],
    ignoreErrors: ['Request failed with status code 401', 'ResizeObserver loop limit exceeded', 'google is not defined', 'Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type', "Cannot read properties of undefined (reading 'code')", "Cannot read properties of undefined (reading 'length')", "Non-Error promise rejection captured with value: close", "e.checkCapslock is not a function", "Non-Error promise rejection captured with keys: code, domains, msg, result", "Non-Error promise rejection captured with keys: errMsg", "Non-Error promise rejection captured with value: cancel", "Non-Error exception captured with keys: code, domains, msg, result", "Network Error", "t.getFullYear is not a function"],
    // Set tracesSampleRate to 1.0 to capture 100%Non-Error exception captured with keys: code, domains, msg, result
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
  Sentry.setUser({
    email: _store.default.getters.email,
    id: _store.default.getters.agentId,
    version: process.env.tag
  });
  var errorHandler = function errorHandler(error, vm) {
    Sentry.captureException(error);
  };
  _vue.default.config.errorHandler = errorHandler;
  _vue.default.prototype.$throw = function (error) {
    return errorHandler(error, _this);
  };
  _vue.default.prototype.$sentry = Sentry;
}
_vue.default.prototype.$version = process.env.tag;
var lang = window.navigator.language.indexOf('ja') != -1 ? 'ja' : 'en';
(0, _index2.loadLanguageAsync)(_store.default.getters.language || lang || 'ja');
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please   it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

_vue.default.use(_htmlToPdf.default);
// Vue.use(VueI18n)
_vue.default.use(_elementUi.default, {
  size: _store.default.getters.size || 'medium',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _index2.i18n.t(key, value);
  }
});
_vue.default.prototype.$errorScroll = function errorScroll(callback) {
  this.$nextTick(function () {
    var isError = document.getElementsByClassName('el-form-item__error');
    isError[0].scrollIntoView({
      // 滚动到指定节点
      block: 'center',
      behavior: 'smooth'
    });
    if (callback) {
      if (typeof callback !== 'function') {
        throw new TypeError(callback + 'is not a function');
      } else {
        callback();
      }
    }
  });
};
window.store = _store.default;

// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
_locale.default.i18n(function (key, value) {
  return _index2.i18n.t(key, value);
});
new _vue.default({
  store: _store.default,
  router: _router.default,
  i18n: _index2.i18n,
  render: function render(h) {
    return h(_App.default);
  }
}).$mount("#app");