"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
_vue.default.use(_vueRouter.default);
/* Layout */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: '',
  redirect: '/login',
  hidden: true
}, {
  path: '/index',
  redirect: '/dashboard',
  hidden: true
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/bindPhone',
  component: function component() {
    return import('@/views/login/bindPhone');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/error-page/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/error-page/401');
  },
  hidden: true
}, {
  path: '/panzhi',
  component: function component() {
    return import('@/views/panzhi/edit');
  },
  meta: {
    title: 'panzhi'
  },
  hidden: true
}, {
  path: '/panzhi/pdf',
  component: function component() {
    return import('@/views/panzhi/pdf');
  },
  meta: {
    title: 'pdf',
    isWhite: true
  },
  hidden: true
},
// {
//   path: '/bindWX',
//   component: () => import('@/views/wechat/BindWX'),
//   // independent title是否添加默认尾缀
//   meta: { title: '绑定账号', independent: true },
//   hidden: true
// },
// {
//   path: '/bindSuccess',
//   component: () => import('@/views/wechat/BindSuccess'),
//   // independent title是否添加默认尾缀
//   meta: { title: '微信绑定', independent: true },
//   hidden: true
// },
// {
//   path: '/sales_inquiry/:id', // /mail/sales_inquiry
//   hidden: true,
//   component: () => import('@/views/wechat/InquiryContent'),
// },
{
  path: '/mail',
  // /mail/sales_inquiry
  hidden: true,
  component: function component() {
    return import('@/views/wechat/Inquiry');
  },
  children: [{
    path: '/inquiry/:id',
    component: function component() {
      return import('@/views/wechat/InquiryContent');
    },
    name: 'inquiry',
    meta: {
      title: '垂询',
      independent: true,
      isWhite: true
    }
  }]
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
var asyncRoutes = exports.asyncRoutes = [{
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    name: 'Dashboard',
    meta: {
      title: '1',
      icon: 'dashboard',
      roles: [111]
    }
  }]
}, {
  path: '/customerManage',
  name: 'CustomerManage',
  component: _layout.default,
  alwaysShow: true,
  redirect: '/customer',
  meta: {
    title: '25',
    icon: 'crm',
    roles: [16, 111, 222]
  },
  children: [{
    name: 'Clue',
    path: '/clue',
    component: function component() {
      return import('@/views/customer/clue');
    },
    meta: {
      title: '2',
      roles: ['GRABBING', 'ADMIN_SUB', 111]
    }
  }, {
    name: 'Customer',
    path: '/customer',
    component: function component() {
      return import('@/views/customer/index');
    },
    meta: {
      title: '3'
    }
  }, {
    name: 'Billing',
    path: '/billing',
    component: function component() {
      return import('@/views/customer/billing');
    },
    meta: {
      title: '4',
      roles: ['GRABBING', 111]
    }
  }, {
    name: 'uploadExcel',
    path: '/uploadExcel',
    component: function component() {
      return import('@/views/customer/uploadExcel');
    },
    meta: {
      title: '上传'
    },
    hidden: true
  }, {
    name: 'InquiryDetail',
    path: '/inquiryDetail',
    component: function component() {
      return import('@/views/customer/inquiryDetail');
    },
    meta: {
      title: '26'
    },
    hidden: true
  }, {
    name: 'AddClientInfo',
    path: '/addClientInfo',
    component: function component() {
      return import('@/views/customer/addClientInfo');
    },
    meta: {
      title: '35'
    },
    hidden: true
  }]
}, {
  path: '/global',
  component: _layout.default,
  name: "global",
  redirect: '/overseasList',
  meta: {
    title: '27',
    icon: 'overseas',
    roles: [222]
  },
  alwaysShow: true,
  children: [{
    path: '/overseasList',
    component: function component() {
      return import('@/views/global/overseasList');
    },
    meta: {
      title: '28'
    }
  }, {
    path: '/overseasEdit',
    name: 'OverseasForm',
    component: function component() {
      return import('@/views/global/overseasForm');
    },
    meta: {
      title: '29',
      roles: ['ADMIN_SUB', 222]
    }
  }]
}, {
  path: '/agent',
  component: _layout.default,
  redirect: 'noRedirect',
  meta: {
    title: '5',
    icon: 'room',
    roles: [4, 111]
  },
  alwaysShow: true,
  children: [{
    path: '/searchMansion',
    component: function component() {
      return import('@/views/agent/room/searchMansion');
    },
    meta: {
      title: '6',
      roles: ['ADMIN_SUB', 111]
    }
  }, {
    path: '/room',
    component: function component() {
      return import('@/views/agent/room/index');
    },
    meta: {
      title: '7'
    }
  }, {
    path: '/roomPreview',
    name: 'roomPreview',
    component: function component() {
      return import('@/views/agent/room/roomPreview');
    },
    meta: {
      title: '36'
    },
    hidden: true
  },
  // {
  //   path: '/searchMansion',
  //   component: () => import('@/views/agent/room/searchMansion'),
  //   meta: {
  //     title: '新規物件追加'
  //   },
  //   hidden: true
  // },
  {
    path: '/editRoom',
    name: 'editEoom',
    component: function component() {
      return import('@/views/agent/room/editRoom');
    },
    meta: {
      title: '',
      noCache: false,
      keepComponentPages: ['roomPreview']
    },
    hidden: true
  }, {
    path: '/sale',
    name: 'saleEdit',
    component: function component() {
      return import('@/views/agent/sale/editSale');
    },
    meta: {
      title: '8',
      roles: ['ADMIN_SUB', 111],
      noCache: false,
      keepComponentPages: ['salePreview']
    }
  }, {
    path: '/salePreview',
    name: 'salePreview',
    component: function component() {
      return import('@/views/agent/sale/salePreview');
    },
    meta: {
      title: '36',
      roles: ['ADMIN_SUB', 111]
    },
    hidden: true
  }, {
    component: function component() {
      return import('@/views/agent/sale/index');
    },
    path: '/saleList',
    meta: {
      title: '9'
    }
  }, {
    path: '/mansionUpdate',
    component: function component() {
      return import('@/views/mansionUpdate/index');
    },
    meta: {
      title: '10',
      roles: ['ADMIN_SUB', 111]
    }
  }]
}, {
  path: '/rental',
  component: _layout.default,
  alwaysShow: true,
  redirect: 'noRedirect',
  meta: {
    title: '34',
    icon: 'rent',
    roles: [64, 111]
  },
  children: [
  // {
  //   path: '/deployEstate',
  //   component: () => import('@/views/rentalList/deployEstate'),
  //   meta: {
  //     title: '物件登録',
  //     roles: ['ADMIN_SUB']
  //   }
  // },
  {
    path: '/rentalList',
    component: function component() {
      return import('@/views/rentalList/index');
    },
    meta: {
      title: '11'
    }
  }, {
    path: '/blueprint',
    component: function component() {
      return import('@/views/blueprint/index');
    },
    meta: {
      title: '間取り図作成|株式会社神居秒算管理システム'
    },
    hidden: true
  }
  // {
  //   path: 'https://www.godtellyou.com/jp/agent/rentalList/5e3241c2b9b2320d816cf743',
  //   meta:
  //   {
  //     title: '賃貸物件リストページ'
  //   }
  // }
  ]
}, {
  path: '/newRoom',
  component: _layout.default,
  redirect: '/list',
  meta: {
    title: '12',
    icon: 'new-room',
    roles: [128, 111]
  },
  alwaysShow: true,
  children: [{
    path: '/newHouse/edit',
    name: 'newRoomEdit',
    component: function component() {
      return import('@/views/newRoom/edit');
    },
    meta: {
      title: '13',
      roles: ['ADMIN_SUB', 111],
      noCache: false,
      keepComponentPages: ['preview']
    }
  }, {
    path: '/list',
    name: 'list',
    component: function component() {
      return import('@/views/newRoom/index');
    },
    meta: {
      title: '14'
    }
  }, {
    path: '/preview',
    name: 'preview',
    component: function component() {
      return import('@/views/newRoom/preview');
    },
    meta: {
      title: 'プレビュー'
    },
    hidden: true
  }, {
    path: '/blueprint',
    component: function component() {
      return import('@/views/blueprint/index');
    },
    meta: {
      title: '間取り図作成|株式会社神居秒算管理システム'
    },
    hidden: true
  }
  // {
  //   path: 'https://www.godtellyou.com/jp/agent/rentalList/5e3241c2b9b2320d816cf743',
  //   meta:
  //   {
  //     title: '賃貸物件リストページ'
  //   }
  // }
  ]
}, {
  path: '/panzhiList',
  component: _layout.default,
  redirect: 'noRedirect',
  meta: {
    title: '16',
    icon: 'flyer',
    roles: [1, 111]
  },
  alwaysShow: true,
  children: [{
    path: '/panzhiList/:type=1',
    component: function component() {
      return import('@/views/panzhi/panzhiList');
    },
    meta: {
      title: '17'
    }
  }, {
    path: '/panzhiList/:type=4',
    component: function component() {
      return import('@/views/panzhi/panzhiList');
    },
    meta: {
      title: '18'
    }
  }, {
    path: '/panzhiList/:type=3',
    component: function component() {
      return import('@/views/panzhi/panzhiList');
    },
    meta: {
      title: '19'
    }
  }, {
    path: '/panzhiList/:type=2',
    component: function component() {
      return import('@/views/panzhi/panzhiList');
    },
    meta: {
      title: '20'
    }
  }]
}, {
  path: '/admin/agent/company/',
  component: _layout.default,
  redirect: 'noRedirect',
  meta: {
    title: '21',
    icon: 'user',
    roles: ['ADMIN', 'ADMIN_SUB', 111, 222]
  },
  alwaysShow: true,
  children: [{
    path: '/company-edit',
    component: function component() {
      return import('@/views/company/company-edit');
    },
    meta: {
      title: '32',
      roles: ['ADMIN', 111, 222]
    }
  }, {
    path: '/updatedPassword',
    component: function component() {
      return import('@/views/updatedPassword/index');
    },
    meta: {
      title: '30'
    }
  }, {
    path: '/secretKey',
    component: function component() {
      return import('@/views/account/secretKey');
    },
    meta: {
      title: '22',
      roles: ['ADMIN_SUB', 111, 222]
    }
  }, {
    path: '/perfectInfo',
    component: function component() {
      return import('@/views/perfectInfo/index');
    },
    // independent title是否添加默认尾缀
    meta: {
      title: '23',
      independent: true
    }
  }, {
    path: '/subaccount',
    component: function component() {
      return import('@/views/account/subaccount');
    },
    meta: {
      title: '33',
      roles: ['ADMIN', 111, 222]
    }
  }, {
    path: '/editAccount',
    component: function component() {
      return import('@/views/account/editAccount');
    },
    meta: {
      title: '31',
      roles: ['ADMIN', 111, 222]
    },
    hidden: true
  }]
}, {
  path: '/manual',
  component: _layout.default,
  children: [{
    path: '/manual',
    component: function component() {
      return import('@/views/manual/index');
    },
    meta: {
      title: '24',
      icon: 'manual',
      roles: [111]
    },
    name: 'manual'
  }]
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
// function getAbsolutePath() {
//   let path = location.pathname
//   console.log(path)
//   let subs = path.split()
//   console.log(subs)

//   if (subs == '/en') {
//     return '/en'
//   }
//   if (subs == '/fr') {
//     return '/fr'
//   }
// }
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: 'history',
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
router.selfaddRoutes = function (router, params) {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
  router.addRoutes(params);
};
var _default = exports.default = router;