"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = _interopRequireDefault(require("@/store"));
var state = {
  overseas_form: {}
};
var mutations = {
  OVERSEAS_DATA: function OVERSEAS_DATA(state, form_data) {
    state.overseas_form = form_data;
  }
};
var actions = {
  setOverseasDatas: function setOverseasDatas(_ref, data) {
    var commit = _ref.commit;
    commit('OVERSEAS_DATA', data);
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};