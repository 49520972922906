"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = _interopRequireDefault(require("@/store"));
var state = {
  estate_form: {}
};
var mutations = {
  ESTATE_DATA: function ESTATE_DATA(state, form_data) {
    state.estate_form = form_data;
  }
};
var actions = {
  setEstateDatas: function setEstateDatas(_ref, data) {
    var commit = _ref.commit;
    commit('ESTATE_DATA', data);
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};