"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js"));
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.trim.js");
require("core-js/modules/es.array.push.js");
var _user = require("@/api/user");
var _clue = require("@/api/clue");
var _account = require("@/api/account");
var state = {
  token: '',
  name: '',
  avatar: '',
  email: '',
  agent_id: '',
  is_perfect: 0,
  is_use_translate: 0,
  domains: '',
  roles: [],
  quota: '',
  showClue: false,
  isCustimerClue: false,
  company: '',
  showAdv: true,
  scope: '',
  type: ''
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_EMAIL: function SET_EMAIL(state, email) {
    state.email = email;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_ADV: function SET_ADV(state, showAdv) {
    state.showAdv = showAdv;
  },
  SET_DOMAIN: function SET_DOMAIN(state, domains) {
    state.domains = domains;
  },
  SET_AGENTID: function SET_AGENTID(state, agent_id) {
    state.agent_id = agent_id;
  },
  SET_IS_PERFECT: function SET_IS_PERFECT(state, is_perfect) {
    state.is_perfect = is_perfect;
  },
  SET_TRANSLATE: function SET_TRANSLATE(state, is_use_translate) {
    state.is_use_translate = is_use_translate;
  },
  SET_QUOtA: function SET_QUOtA(state, quota) {
    state.quota = quota;
  },
  SET_SCOPE: function SET_SCOPE(state, scope) {
    state.scope = scope;
  },
  SET_COMPANY: function SET_COMPANY(state, company) {
    state.company = company;
  },
  SET_CLUE: function SET_CLUE(state, showClue) {
    state.showClue = showClue ? true : false;
  },
  SET_TYPE: function SET_TYPE(state, type) {
    state.type = type;
  },
  SET_CUSTOMERCLUE: function SET_CUSTOMERCLUE(state, isCustimerClue) {
    state.isCustimerClue = isCustimerClue ? true : false;
  }
};
var actions = {
  // 显示/隐藏
  showAdVisible: function showAdVisible(_ref, visible) {
    var commit = _ref.commit;
    commit('SET_ADV', visible);
  },
  // user login 暂时没用
  login: function login(_ref2, userInfo) {
    var commit = _ref2.commit;
    var username = userInfo.username,
      password = userInfo.password;
    return new Promise(function (resolve, reject) {
      (0, _user.signin)({
        username: username.trim(),
        password: password
      }).then(function (response) {
        var domains = response.domains;
        var result = response.result;
        var roles = result.roles,
          name = result.name,
          avatar = result.avatar,
          id = result.id,
          email = result.email,
          is_perfect = result.is_perfect,
          company = result.company,
          scope = result.scope,
          is_use_translate = result.is_use_translate,
          is_parent = result.is_parent,
          type = result.type;
        var newRoles = (0, _toConsumableArray2.default)(roles);
        // roles must be a non-empty array
        if (!newRoles || newRoles.length <= 0) {
          reject('ログインに失敗しました。メールアドレスとパスワードをご確認の上、再度実行してください。');
        }
        var account = is_parent ? 'ADMIN' : 'ADMIN_SUB';
        if (scope === 2) {
          newRoles = [];
        }
        newRoles.push(account);
        // newRoles.push(scopeVal);
        var obj = Object.assign(result, domains);
        commit('SET_ROLES', newRoles);
        commit('SET_SCOPE', scope);
        commit('SET_DOMAIN', domains);
        commit('SET_NAME', name);
        commit('SET_EMAIL', email);
        commit('SET_AVATAR', avatar);
        commit('SET_AGENTID', id);
        commit('SET_COMPANY', company);
        commit('SET_IS_PERFECT', is_perfect);
        commit('SET_TRANSLATE', is_use_translate);
        commit('SET_TOKEN', result.access_token);
        commit('SET_TYPE', type);
        // setToken(result.access_token)
        // setUserInfo(JSON.stringify(obj))
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 抢单
  getCustomerClue: function getCustomerClue(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      var obj = {
        page: 1,
        limit: 1000,
        status: 1
      };
      (0, _clue.getCustomerClue)(obj).then(function (res) {
        if (res.code === 1000200) {
          var data = res.result.data;
          if (data.length) {
            resolve(true);
            commit('SET_CUSTOMERCLUE', 1);
          } else {
            commit('SET_CUSTOMERCLUE', 0);
          }
        }
      });
    });
  },
  // 配额
  accountQuota: function accountQuota(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve, reject) {
      (0, _account.getAccountQuota)().then(function (res) {
        if (res.code === 1000200) {
          var result = res.result;
          commit('SET_QUOtA', result);
          resolve();
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getUserInfo: function getUserInfo(_ref5) {
    var commit = _ref5.commit,
      state = _ref5.state;
    return new Promise(function (resolve, reject) {
      // 获取用户信息 如果返回401 则去获取微信信息
      (0, _user.getUserInfo)().then(function (response) {
        var domains = response.domains;
        var result = response.result;
        if (!result) {
          reject('ログインに失敗しました。メールアドレスとパスワードをご確認の上、再度実行してください。');
        }
        var roles = result.roles,
          name = result.name,
          avatar = result.avatar,
          id = result.id,
          email = result.email,
          is_perfect = result.is_perfect,
          is_use_translate = result.is_use_translate,
          is_parent = result.is_parent;
        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          reject('ログインに失敗しました。メールアドレスとパスワードをご確認の上、再度実行してください。');
        }
        var account = is_parent ? 'ADMIN' : 'ADMIN_SUB';
        roles.push(account);
        commit('SET_ROLES', roles);
        commit('SET_DOMAIN', domains);
        commit('SET_NAME', name);
        commit('SET_EMAIL', email);
        commit('SET_AVATAR', avatar);
        commit('SET_AGENTID', id);
        commit('SET_IS_PERFECT', is_perfect);
        commit('SET_TRANSLATE', is_use_translate);
        resolve(result);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 重置用户信息
  resetInfo: function resetInfo(_ref6) {
    var commit = _ref6.commit;
    return new Promise(function (resolve) {
      commit('SET_ROLES', []);
      commit('SET_NAME', '');
      commit('SET_AVATAR', '');
      commit('SET_AGENTID', '');
      resolve();
    });
  },
  // 退出
  logout: function logout(_ref7) {
    var _this = this;
    var commit = _ref7.commit,
      state = _ref7.state;
    return new Promise(function (resolve, reject) {
      commit('SET_TOKEN', false);
      commit('SET_ROLES', []);
      _this.commit('permission/SET_NO_ROUTES', []);
      _this.commit('globalData/OVERSEAS_DATA', {});
      resolve();
      location.reload();
      //   logout().then((res) => {
      //     if (res.code === 1000200) {

      //       // removeToken()
      //       // reset visited views and cached views
      //       // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
      //       // dispatch('tagsView/delAllViews', null, { root: true })
      //       resolve()
      //     }
      //   }).catch(error => {
      //     reject(error)
      //   })
    });
  },
  // remove token 暂时没用
  resetToken: function resetToken(_ref8) {
    var commit = _ref8.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      // removeToken()
      resolve();
    });
  } // dynamically modify permissions
  // changeRoles({ commit, dispatch }, role) {
  //   return new Promise(async resolve => {
  //     //const token = role + '-token'
  //     //commit('SET_TOKEN', token)
  //     //setToken(token)
  //     const { roles } = await dispatch('getInfo')
  //     resetRouter()
  //     // generate accessible routes map based on roles
  //     const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
  //     // dynamically add accessible routes
  //     router.addRoutes(accessRoutes)
  //     // reset visited views and cached views
  //     //dispatch('tagsView/delAllViews', null, { root: true })
  //     resolve()
  //   })
  // }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};