"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addChildAccount = addChildAccount;
exports.bindPhone = bindPhone;
exports.childAccountDetail = childAccountDetail;
exports.childAccountList = childAccountList;
exports.deleteChildAccount = deleteChildAccount;
exports.editChildAccount = editChildAccount;
exports.getAccountQuota = getAccountQuota;
exports.getIps = getIps;
exports.getPerfectInfo = getPerfectInfo;
exports.getToken = getToken;
exports.hasToken = hasToken;
exports.sendCode = sendCode;
exports.setIps = setIps;
exports.updateAvatar = updateAvatar;
var _request = _interopRequireDefault(require("@/utils/request"));
// 子账户列表
function childAccountList(query) {
  return (0, _request.default)({
    url: '/account',
    method: 'get',
    baseUrl: '/v1',
    params: query
  });
}
// 添加子账户
function addChildAccount(data) {
  return (0, _request.default)({
    url: '/account',
    method: 'post',
    data: data,
    baseUrl: '/v1'
  });
}
// 绑定手机号
function bindPhone(data) {
  return (0, _request.default)({
    url: '/account/profile/phone',
    method: 'post',
    data: data,
    baseUrl: '/v3'
  });
}
// 子账户详情
function childAccountDetail(param) {
  return (0, _request.default)({
    url: '/account/' + param,
    method: 'get',
    baseUrl: '/v1'
  });
}
// 获取可配额度
function getAccountQuota() {
  return (0, _request.default)({
    url: '/account/quota',
    method: 'get',
    baseUrl: '/v1'
  });
}
// 更新子账户
function editChildAccount(param, data) {
  return (0, _request.default)({
    url: '/account/' + param,
    method: 'put',
    baseUrl: '/v1',
    data: data
  });
}
// 删除子账户
function deleteChildAccount(data) {
  return (0, _request.default)({
    url: '/account/' + data,
    method: 'delete',
    baseUrl: '/v1'
  });
}
// 发送验证码
function sendCode(data) {
  return (0, _request.default)({
    url: '/common/captcha',
    method: 'post',
    data: data,
    baseUrl: '/v3'
  });
}
// 更新头像
function updateAvatar(data) {
  return (0, _request.default)({
    url: '/account/profile/avatar',
    method: 'post',
    data: data,
    baseUrl: '/v3'
  });
}
// 获取中介信息
function getPerfectInfo() {
  return (0, _request.default)({
    url: '/account/profile/detail',
    method: 'get',
    baseUrl: '/v3'
  });
}

// open api生成Token
function getToken(data) {
  return (0, _request.default)({
    url: '/account/openapi/token',
    method: 'post',
    data: data,
    baseUrl: '/v3'
  });
}

// open api获取已有Token
function hasToken() {
  return (0, _request.default)({
    url: '/account/openapi/token',
    method: 'get',
    baseUrl: '/v3'
  });
}
// 设置白名单
function setIps(data) {
  return (0, _request.default)({
    url: '/account/openapi/ips',
    method: 'post',
    data: data,
    baseUrl: '/v3'
  });
}
// 获取白名单
function getIps(data) {
  return (0, _request.default)({
    url: '/account/openapi/ips',
    method: 'get',
    baseUrl: '/v3'
  });
}