"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _panzhi = require("@/api/panzhi");
var state = {
  type: window.sessionStorage.getItem('type'),
  estateId: window.sessionStorage.getItem('estateId')
};
var mutations = {
  ESTATETYPE: function ESTATETYPE(state, type) {
    state.type = type;
    window.sessionStorage.setItem('type', type);
  },
  ITEMID: function ITEMID(state, estateId) {
    state.estateId = estateId;
    window.sessionStorage.setItem('estateId', estateId);
  }
};
var actions = {
  getFlyer: function getFlyer(_ref, data) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      (0, _panzhi.getFlyer)(data.type, data.id).then(function (res) {
        if (res.code === 1000200) {
          commit('ESTATETYPE', res.result.type);
          commit('ITEMID', res.result.item_id);
          resolve(res.result);
        } else {
          reject(res);
        }
      }).catch(function (err) {
        reject(err);
      });
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};