"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementVariables = _interopRequireDefault(require("@/styles/element-variables.scss"));
var _settings = _interopRequireDefault(require("@/settings"));
var showSettings = _settings.default.showSettings,
  tagsView = _settings.default.tagsView,
  fixedHeader = _settings.default.fixedHeader,
  sidebarLogo = _settings.default.sidebarLogo,
  isConnect = _settings.default.isConnect;
var state = {
  theme: (_elementVariables.default === null || _elementVariables.default === void 0 ? void 0 : _elementVariables.default.theme) || '',
  showSettings: showSettings,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  im: '',
  isConnect: ''
};
var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
    var key = _ref.key,
      value = _ref.value;
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  },
  // TOTALUNREADCOUNT: (state, count) => {
  // 	state.totalUnreadCount = count
  // },
  SETIMOBJECT: function SETIMOBJECT(state, im) {
    state.im = im;
  },
  SETCONNECT: function SETCONNECT(state, value) {
    state.isConnect = value;
  }
};
var actions = {
  changeSetting: function changeSetting(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  },
  // setUnreadCount({ commit }, data) { // 设置chat未读数
  // 	commit('TOTALUNREADCOUNT', data)
  // },
  setInitIm: function setInitIm(_ref3, data) {
    var commit = _ref3.commit;
    commit('SETIMOBJECT', data);
  },
  setConnect: function setConnect(_ref4, data) {
    var commit = _ref4.commit;
    commit('SETCONNECT', data);
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};