"use strict";

var _interopRequireDefault = require("/builds/frontend/web/agent/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
var _getters = _interopRequireDefault(require("./getters"));
var _vuexPersistedstate = _interopRequireDefault(require("vuex-persistedstate"));
var _action = _interopRequireDefault(require("./admin-panzhi/common/action"));
// import action from './admin-panzhi/common/action'
// import   from './admin-panzhi/common/'

// import user from './modules/user';
_vue.default.use(_vuex.default);

// https://webpack.js.org/guides/dependency-management/#requirecontext
var modulesFiles = require.context('./modules', true, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
var modules = modulesFiles.keys().reduce(function (modules, modulePath) {
  // set './app.js' => 'app'
  var moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  var value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
var store = new _vuex.default.Store({
  modules: modules,
  action: _action.default,
  getters: _getters.default,
  plugins: [(0, _vuexPersistedstate.default)({
    // 持久化存储的关键字key 自定义即可
    key: "Vuex",
    // ['LoginInfo','UserInfo']要持久化的状态，在state里面取，如果有嵌套，可以  a.b.c
    paths: ['user', 'app', 'globalData', 'estate'],
    storage: window.sessionStorage,
    reducer: function reducer(val) {
      return {
        user: val.user,
        app: val.app,
        globalData: val.globalData,
        estate: val.estate
      };
    } // 存储方式定义
  })]
});
var _default = exports.default = store;